import 'rc-slider/assets/index.css';
import '../css/app.css';
import './bootstrap';
import './fonts/line-awesome-1.3.0/css/line-awesome.css';
import './styles/index.scss';
// Sentry initialization should be imported first!
//import "./lib/instrument.js";

import { RouteContext } from '@/Hooks/useRoute';
import { createInertiaApp } from '@inertiajs/react';
//import { ModalStackProvider, ModalRoot } from '@inertiaui/modal-react'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
//import { createRoot } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

import { Toaster } from 'sonner';

const appName =
  window.document.getElementsByTagName('title')[0]?.innerText || '+Pisto';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  progress: {
    color: '#30951c',
    showSpinner: true,
  },
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx'),
    ),
  setup({ el, App, props }) {
    const root = createRoot(el);
    return root.render(
      // <StrictMode>
      // <ModalStackProvider>
      // <ModalRoot>
      <RouteContext.Provider value={(window as any).route}>
        <Toaster richColors position="top-center" />
        <App {...props} />
      </RouteContext.Provider>,
      // </ModalRoot>
      // </ModalStackProvider>,
      // </StrictMode>
    );
    // Server side render
    /* hydrateRoot(el,
          <RouteContext.Provider value={(window as any).route}>
            <Toaster richColors position="top-center" />
            <App {...props} />
          </RouteContext.Provider>
          ) */
  },
});
